import React from "react";

import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="page d-flex min-vh-100 flex-column justify-content-center">
      <div className="page-content">
        <div className="container text-center">
          <div className="display-1 text-muted mb-5 font-weight-bold">404</div>
          <h1 className="h2 mb-3">Oops.. You just found an error page..</h1>
          <p className="h4 text-muted mb-7">
            We are sorry but our service is currently not available&hellip;
          </p>
          <a className="btn btn-primary" href="javascript:history.back()">
            <i className="fal fa-arrow-left mr-2" />
            Go back
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
